<template>
    <div>
        <Header title="Mensagens" addText="Mensagem" :addFields="addFields" :meta="meta"/>
        <b-table sticky-header="65vh" no-border-collapse hover :items="messages" :fields="fields">
          <template #cell(actions)="row">
                <div class="btn-icons">
                    <div><i @click="$emit('callModal', 'form-modal', 'edit', row.item, addFields, meta, null, '')" class = "material-icons btn-icon">edit</i><span>Editar</span></div>
                    <div><i @click="$emit('callModal', 'delete-modal', 'edit', row.item, addFields, meta, null, '', /* addFieldsToGroup, 'attachToGroup', */ deleteMessage)" class = "material-icons btn-icon">delete</i><span>Deletar</span></div>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>

import Header from '@/components/Dashboard/Header'

import api from '@/services/condinvest'
import { toast } from '@/utils'

export default {
  components: {
    Header
  },
  created () {
    api.get('message/template/index').then((response) => {
      console.log(response.data)
      if (response.status === 200) {
        this.messages = response.data.messages
      }
    }, error => {
      toast(this, 'danger', 'Erro ao obter mensagens', error.message)
      return Promise.reject(error)
    })
  },
  data: () => ({
    messages: [],
    meta: {
      title: 'Mensagem',
      module: 'messages',
      state: 'messages',
      noDispatch: true,
      modes: {
        add: {
          title: 'Enviar Mensagem',
          success: 'Mensagem enviada com successo',
          urn: 'message/template/create',
          response: 'message',
          method: 'post'
        },
        edit: {
          title: 'Editar Mensagem',
          success: 'Mensagem editada com successo',
          urn: 'message/template/edit',
          response: 'message',
          method: 'post'
        }
      }
    },
    fields: [
      { key: 'title', label: 'Título', sortable: true },
      { key: 'content', label: 'Conteúdo', sortable: true },
      { key: 'actions', label: 'Ações', sortable: true }
    ],
    addFields: [
      { key: 'title', label: 'Título', type: 'text' },
      { key: 'content', label: 'Conteúdo', type: 'textarea' },
      { key: 'priority', label: 'Prioridade', type: 'select', options: [{ value: '1', text: 'Urgente' }, { value: '2', text: 'Alta' }, { value: '3', text: 'Média' }, { value: '4', text: 'Normal' }, { value: '5', text: 'Baixa' }] },
      { key: 'action', label: 'Ação', type: 'select', options: [{ value: 'bill_expirations', text: 'Conta vencida' }] },
      { key: 'type', label: 'Tipo', type: 'select', options: [{ value: 'before', text: 'Antes' }, { value: 'after', text: 'Depois' }] },
      { key: 'days', label: 'Dias', type: 'number' },
      { key: 'send_id', type: 'hidden', value: { store: 'state.user.id' } },
      { key: 'cronjob_type', label: 'Horário de Notificação', type: 'select', options: [{ value: '10:00', text: '10:00' }, { value: '18:00', text: '18:00' }] }
    ]
  }),
  methods: {
    deleteMessage (o) {
      api.delete('message/template/delete?id=' + o.id).then((response) => {
        console.log(response.data)
        if (response.status >= 200 || response.status < 300) {
          this.$router.go()
        }
      }, error => {
        toast(this, 'danger', 'Erro ao deletar mensagem', error.message)
        return Promise.reject(error)
      })
    }
  }
}
</script>
